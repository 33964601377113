import { Link, withPrefix } from "gatsby";
import React from "react";
import scrollToElement from "scroll-to-element";

import { Layout } from "../components";
import FormLandingPages from "../components/FormLandingPages";
import locales from "../constants";

const VoxDeveloper = ({ pageContext: { slug } }) => {
  const lang = "pl";

  const offer_content = [
    {
      icon: require("../assets/img/vox-dev/dev-icon1.svg"),
      title: "Szybką i sprawną implementację integracji,",
      desc: "umożliwiającą automatyczne synchronizowanie danych między stroną internetową a systemem CRM, co eliminuje potrzebę ręcznego uaktualniania informacji na stronie.",
    },
    {
      icon: require("../assets/img/vox-dev/dev-icon2.svg"),
      title:
        "Dostosowanie integracji do indywidualnych potrzeb Twojej inwestycji,",
      desc: "uwzględniając różne rodzaje danych i ich strukturę.",
    },
    {
      icon: require("../assets/img/vox-dev/dev-icon3.svg"),
      title: "Zapewnienie bezpiecznego i stabilnego połączenia",
      desc: "między stroną internetową a systemem CRM, co gwarantuje ochronę danych i niezawodność usługi.",
    },
    {
      icon: require("../assets/img/vox-dev/dev-icon4.svg"),
      title: "Monitorowanie i utrzymanie integracji,",
      desc: "aby zapewnić ciągłość funkcjonowania oraz szybkie reagowanie na ewentualne problemy.",
    },
    {
      icon: require("../assets/img/vox-dev/dev-icon5.svg"),
      title:
        "Wdrożenie struktury danych o inwestycji w systemie CMS na stronie internetowej,",
      desc: "aby dane na stronie były zawsze dostępne bez względu na to czy system CRM je w danej chwili dostarcza.",
    },
  ];
  return (
    <Layout
      seo={{
        title: "VoxDeveloper CRM",
        href: slug,
        lang: "pl",
        ogImage: withPrefix("/og-image-vox-developer.jpg"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/ux-audit/",
      // }}
      header={{ icons: "#C2CE08" }}
    >
      <section className="container-fluid vox-dev__hero">
        <h1>
          Integracja strony Twojej inwestycji <br />z{" "}
          <span>VoxDeveloper CRM</span>
        </h1>
        <button
          className="scrollPortfolio"
          onClick={() => scrollToElement("#intro")}
          aria-label="Scroll to intro"
        />
      </section>
      <section className="container-fluid vox-dev__integration" id="intro">
        <div className="row">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <h2>Intergacja z VoxDeveloper CRM</h2>
            <p className="vox-dev__integration-intro">
              Jeśli rozważasz wybór systemu CRM Vox dla swojej inwestycji
              deweloperskiej lub condohotelowej stoisz zapewne również przed
              decyzją o integracji danych z stroną internetową.{" "}
              <strong>
                Jako zespół specjalizujący się w tworzeniu stron internetowych i
                marketingu dla deweloperów
              </strong>{" "}
              przedstawiamy jak działa taka integracja z systemem CRM Vox i co w
              takich integracjach jest istotne.
            </p>
            <div className="row vox-dev__integration-row">
              <div className="col-md-6 pr-md-0">
                <div className="vox-dev__integration-img">
                  <img src={require("../assets/img/vox-dev/int1.jpg")} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="vox-dev__integration-desc">
                  <p>Czas</p>
                  <p>
                    W projektach deweloperskich czas ma bardzo duże znaczenie
                    dlatego nie ma czasu na wymyślanie koła na nowo. Posiadanie
                    profesjonalnej i sprawdzonej integracji z systemem CRM Vox
                    zaoszczędzi sporo środków oraz czasu potrzebnego na oddanie
                    projektu.
                  </p>
                </div>
              </div>
            </div>
            <div className="row vox-dev__integration-row">
              <div className="col-md-6 order-2 order-md-1">
                <div className="vox-dev__integration-desc">
                  <p>Doświadczenie</p>
                  <p>
                    Dzięki naszemu doświadczeniu i wielu wdrożeniach u
                    zadowolonych klinetów możemy szybko i sprawnie tworzyć
                    strony internetowe, które automatycznie synchronizują się z
                    danymi z CRM, takimi jak informacje o inwestycjach, lokalach
                    i innych szczegółach. Integracja ta pozwala oszczędzić czas
                    i zasoby, jednocześnie gwarantując wysoką jakość usług.
                  </p>
                </div>
              </div>
              <div className="col-md-6 pl-md-0 order-1 order-md-2">
                <div className="vox-dev__integration-img">
                  <img src={require("../assets/img/vox-dev/int2.jpg")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid vox-dev__offer">
        <div className="row">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <h2>Nasza oferta integracji z systemem CRM Vox obejmuje:</h2>
            {offer_content.map((item, index) => {
              return (
                <div key={index} className="vox-dev__offer-item">
                  <div className="vox-dev__offer-img">
                    <img src={item.icon} alt="" />
                  </div>
                  <div className="vox-dev__offer-details">
                    <p>{item.title}</p>
                    <p>{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="container-fluid vox-dev__portfolio">
        <div className="row">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <h2>
              Powyższe rozwiazanie wdrożyliśmy m.in w takich projektach jak
              condo.pl, marshallre.pl, czy rodzinneresorty.pl.
            </h2>
            <div className="vox-dev__portfolio-item">
              <div className="vox-dev__portfolio-link">
                <a
                  href="https://marshallre.pl/"
                  target="_blank"
                  className="vox-dev__portfolio-btn"
                  rel="noreferrer"
                >
                  marshallre.pl
                </a>
                <a
                  href="https://marshallre.pl/"
                  target="_blank"
                  className="btn_custom"
                  rel="noreferrer"
                >
                  Odwiedź stronę
                </a>
              </div>
              <div className="vox-dev__portfolio-sample">
                <div className="vox-dev__portfolio-laptop">
                  <div className="scroll_wrapper">
                    <div className="img_wrapper">
                      <img
                        src={require("../assets/img/vox-dev/marschal_full.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="vox-dev__portfolio-list">
                  <img
                    src={require("../assets/img/vox-dev/marshallre_list.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="vox-dev__portfolio-item vox-dev__portfolio-item--reverse">
              <div className="vox-dev__portfolio-link">
                <a
                  href="https://condo.pl/"
                  target="_blank"
                  className="vox-dev__portfolio-btn"
                  rel="noreferrer"
                >
                  condo.pl
                </a>
                <a
                  href="https://condo.pl/"
                  target="_blank"
                  className="btn_custom"
                  rel="noreferrer"
                >
                  Odwiedź stronę
                </a>
              </div>
              <div className="vox-dev__portfolio-sample">
                <div className="vox-dev__portfolio-laptop">
                  <div className="scroll_wrapper">
                    <div className="img_wrapper">
                      <img
                        src={require("../assets/img/vox-dev/condo_full.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="vox-dev__portfolio-list">
                  <img
                    src={require("../assets/img/vox-dev/condo_list.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="vox-dev__portfolio-item">
              <div className="vox-dev__portfolio-link">
                <a
                  href="https://rodzinneresorty.pl/"
                  target="_blank"
                  className="vox-dev__portfolio-btn"
                  rel="noreferrer"
                >
                  rodzinneresorty.pl
                </a>
                <a
                  href="https://rodzinneresorty.pl/"
                  target="_blank"
                  className="btn_custom"
                  rel="noreferrer"
                >
                  Odwiedź stronę
                </a>
              </div>
              <div className="vox-dev__portfolio-sample">
                <div className="vox-dev__portfolio-laptop">
                  <div className="scroll_wrapper">
                    <div className="img_wrapper">
                      <img
                        src={require("../assets/img/vox-dev/rodzinne_full.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="vox-dev__portfolio-list">
                  <img
                    src={require("../assets/img/vox-dev/rodzinneresorty_list.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid vox-dev__info">
        <div className="row">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <h2>
              Chciałbyś dowiedzieć się więcej o naszej ofercie dla deweloperów?
            </h2>
            <div className="row vox-dev__info-row">
              <div className="col-md-6 pr-md-0">
                <div className="vox-dev__info-img">
                  <img src={require("../assets/img/vox-dev/dev1.jpg")} alt="" />
                  <p>Marketing dla Deweloperów</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="vox-dev__info-desc">
                  <p>
                    Odwiedź naszą stronę Marketing dla deweloperów, gdzie
                    znajdziesz{" "}
                    <strong>
                      informacje na temat tworzenia stron internetowych i
                      marketingu dla deweloperów.
                    </strong>{" "}
                    Oferujemy usługi dostosowane do Twoich potrzeb i oczekiwań.
                  </p>
                  <div>
                    <Link
                      to="/marketing-dla-deweloperow/"
                      className="btn_custom"
                    >
                      Dowiedz się więcej
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row vox-dev__info-row">
              <div className="col-md-6 order-2 order-md-1">
                <div className="vox-dev__info-desc">
                  <p>
                    Dodatkowo, mamy w ofercie dedykowane synchronizatory i
                    integracje.{" "}
                    <strong>
                      Zapewniamy indywidualne podejście do każdego klienta i
                      dostosowanie integracji do Twojego systemu.
                    </strong>
                  </p>
                  <div>
                    <Link
                      to="/dedykowane-synchronizatory-integracje-systemow/"
                      className="btn_custom"
                    >
                      Dowiedz się więcej
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pl-md-0 order-1 order-md-2">
                <div className="vox-dev__info-img">
                  <img src={require("../assets/img/vox-dev/dev2.jpg")} alt="" />
                  <p>
                    Dedykowane synchronizatory <br />i integracje systemów
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid vox-dev__contact">
        <div className="row">
          <div className="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
            <h2>
              Nie czekaj, skontaktuj się z nami, żeby dowiedzieć się więcej na
              temat naszej oferty i korzyści płynących z integracji z systemem
              CRM Vox. Razem przyczynimy się do sukcesu Twojej inwestycji!
            </h2>
            <div className="vox-dev__contact-form">
              <FormLandingPages
                locales={locales[lang]}
                lang={lang}
                landing_page="integracja-vox-developer-crm-z-strona-www"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VoxDeveloper;
